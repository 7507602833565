import React from "react";

export const Settings = () => {
  return (
    <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
      <h1>Settings</h1>
    </div>
  );
};

export default Settings;
