import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";

// Hook
function useOnClickOutside(ref:any, handler:any) {
  useEffect(
    () => {
      const listener = (event:any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const NavBar = () => {
  const ref = React.createRef<HTMLDivElement>();
  const rootStore = useContext(RootStoreContext);
  const { shareholder, logout } = rootStore.shareholderStore;
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("dashboard");
  
  useOnClickOutside(ref, () => setModalOpen(false));

  return (
    <nav id="header" className="bg-white fixed w-full z-10 top-0 shadow">
      <div className="w-full container mx-auto flex flex-wrap items-center mt-0 pt-3 pb-3 md:pb-0">
        <div className="w-1/2 pl-2 md:pl-0">
          <Link
            className="text-gray-900 text-base xl:text-xl no-underline hover:no-underline font-bold"
            to="/"
          >
            Muriwhenua Share Register
          </Link>
        </div>
        <div className="w-1/2 pr-0">
          <div className="flex relative inline-block float-right">
            <div className="relative text-sm" ref={ref}>
              <button
                id="userButton"
                onClick={() => setModalOpen(true)}
                className="flex items-center focus:outline-none mr-3"
              >
                {/* <img
                  className="w-8 h-8 rounded-full mr-4"
                  src="http://i.pravatar.cc/300"
                  alt="Avatar of User"
                />{" "} */}
                <span className="hidden md:inline-block">Hi, {shareholder?.first_name} </span>
              </button>
              <div
                id="userMenu"
                className={"bg-white rounded shadow-md mt-2 absolute mt-12 top-0 right-0 min-w-full overflow-auto z-30 " + (isModalOpen ? '' : 'invisible')}
              >
                <ul className="list-reset">
                  <li>
                    <a
                      href="/"
                      onClick={() => logout()}
                      className="px-4 py-2 block text-gray-900 hover:bg-gray-400 no-underline hover:no-underline"
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="block lg:hidden pr-4">
              <button
                id="nav-toggle"
                className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-blue-700 hover:border-teal-500 appearance-none focus:outline-none"
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div
          className="w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 bg-white z-20 hidden"
          id="nav-content"
        >
          <ul className="list-reset lg:flex flex-1 items-center px-4 md:px-0">
            <li className="mr-6 my-2 md:my-0">
              <Link
                to="/dashboard"
                onClick={() => setActiveMenu('dashboard')}
                className={"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-blue-700 border-b-2 " + (activeMenu === 'dashboard' ? 'text-blue-700': 'border-white text-gray-500')}
              >
                <i className="fas fa-home fa-fw mr-3"></i>
                <span className="pb-1 md:pb-0 text-sm">Dashboard</span>
              </Link>
            </li>
            <li className="mr-6 my-2 md:my-0">
              <Link
                to="/account"
                onClick={() => setActiveMenu('account')}
                className={"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-blue-700 border-b-2 " + (activeMenu === 'account' ? 'text-blue-700': 'border-white text-gray-500' )}
              >
                <i className="fas fa-tasks fa-fw mr-3"></i>
                <span className="pb-1 md:pb-0 text-sm">Account</span>
              </Link>
            </li>
            <li className="mr-6 my-2 md:my-0">
              <Link
                onClick={() => setActiveMenu('settings')}
                to="/settings"
                className={"block py-1 md:py-3 pl-1 align-middle no-underline hover:text-blue-700 border-b-2 " + (activeMenu === 'settings' ? 'text-blue-700': 'border-white text-gray-500')}
              >
                <i className="fa fa-envelope fa-fw mr-3"></i>
                <span className="pb-1 md:pb-0 text-sm">Settings</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default observer(NavBar);
