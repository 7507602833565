import { observable, computed, action, runInAction } from 'mobx';
import { IShareHolder, ILoginRequest, IResetPasswordRequest } from '../models/shareholder';
import agent from '../api/agent';
import { RootStore } from './rootStore';
import { history } from '../..';
import { toast } from 'react-toastify';

export default class ShareholderStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable shareholder: IShareHolder | null = null;
  @observable children: IShareHolder[] = [];
  @observable parents: IShareHolder[] = [];
  @observable errorMessage: string = "";

  @computed get isLoggedIn() {
    return !!this.rootStore.commonStore.token && window.localStorage.getItem('jwt');
  }

  @action login = async (values: ILoginRequest) => {
    try {
      const shareholder = await agent.ShareHolder.login(values);
      
      runInAction('login action', () => {
        this.shareholder = shareholder.user.data;
        this.rootStore.commonStore.setToken(shareholder.token);
        this.rootStore.modalStore.closeModal();
        history.push('/dashboard');
      });
      
    } catch (error) {
      throw error;
    }
  }; 

  @action forgot = async (email: string) => {
    try {
      const response = await agent.ShareHolder.forgot(email);
      if (response.status === 'failed') {
        runInAction('forgot action', () => {
          this.errorMessage = response.message;
          throw response;
        });  
      } else {
        runInAction('forgot action', () => {
          toast.success('Forgot password successfully sent')
          this.rootStore.modalStore.closeModal();
        });
      }
    } catch (error) {
      throw error;
    }
  }; 

  @action reset = async (values: IResetPasswordRequest) => {
    try {
      const response = await agent.ShareHolder.reset(values);
      if (response.status === 'failed') {
        runInAction('reset action failed', () => {
          this.errorMessage = response.message;
          throw response;
        });  
      } else {
        runInAction('reset action success', () => {
          toast.success('Your password has been reset!')
          history.push('/');
        });
      }
      
    } catch (error) {
      throw error;
    }
  };  

  @action getUser = async () => {
    try {
      const shareholder = await agent.ShareHolder.current();
      runInAction('getUser', () => {
        this.shareholder = shareholder;
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  @action getChildren = async () => {
    try {
      const children = await agent.ShareHolder.children(this.shareholder?.id!);
      runInAction('getChildren', () => {
        this.children = children.data;
      });
    } catch (error) {
      console.log(error);
    }
  };
  
  @action getParents = async () => {
    try {
      const parents = await agent.ShareHolder.parents(this.shareholder?.id!);
      runInAction('getParents', () => {
        this.parents = parents.data;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.shareholder = null;
    history.push('/');
  };
}
