import React, { useContext, useEffect } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { Children } from "./Childrens";
import { Parents } from "./Parents";

export const Account = () => {
  const rootStore = useContext(RootStoreContext);
  const { shareholder } = rootStore.shareholderStore;
  const {getChildren, children} = rootStore.shareholderStore;
  const {getParents, parents} = rootStore.shareholderStore;
  
  useEffect(() => {
    getParents();
    getChildren();
  }, [getParents, getChildren]);
  
  return (
    <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
      <div id="section2" className="p-8 mt-6 mb-10 lg:mt-0 rounded shadow bg-white">
        <form>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                SRN
              </label>
              <p className="text-gray">{shareholder?.srn}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <p className="text-gray">{shareholder?.first_name}</p>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <p className="text-gray">{shareholder?.last_name}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Email
              </label>
              <p className="text-gray">{shareholder?.email}</p>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Phone
              </label>
              <p className="text-gray">{shareholder?.phone}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Address 1
              </label>
              <p className="text-gray">{shareholder?.address1}</p>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Address 2
              </label>
              
               <p className="text-gray">{shareholder?.address2}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                City
              </label>
              <p className="text-gray">{shareholder?.city}</p>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                State
              </label>
              <p className="text-gray">{shareholder?.state}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Postal Code
              </label>
              <p className="text-gray">{shareholder?.postal_code}</p>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                DOB
              </label>
              <p className="text-gray">{shareholder?.dob}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Gender
              </label>
              <p className="text-gray">{shareholder?.gender === 'f' ? 'Female': 'Male'}</p>
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Status
              </label>
              <p className="text-gray">{shareholder?.status === 1 ? 'Active': 'In-active'}</p>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block  tracking-wide text-gray-900 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                Life Interest
              </label>
              <p className="text-gray">{shareholder?.life_interest === true ? 'Yes': 'No'}</p>
            </div>
          </div>
        </form>
      </div>
      <Children children={children}></Children>
      <Parents parents={parents}></Parents>
    </div>
  );
};

export default observer(Account);
