import React from "react";
import { observer } from "mobx-react-lite";
import ResetPasswordForm from "./ResetPasswordForm";
import {
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ResetPage = () => {
  let query = useQuery();
  return (
    <div className="home flex h-screen mx-auto sm:px-4 pt-6 pb-8">
      <div className="m-auto">
        <div className="container mx-auto px-6 py-24 content-center text-center">
          <div className="w-full">
            <ResetPasswordForm email={query.get("email")!} token={query.get("token")!}></ResetPasswordForm>  
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ResetPage);