import React, { useContext, Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import LoginForm from "../user/LoginForm";
import ForgotPasswordForm from "../user/ForgotPasswordForm";

export const HomePage = () => {
  const rootStore = useContext(RootStoreContext);
  const { shareholder, isLoggedIn } = rootStore.shareholderStore;
  const { openModal } = rootStore.modalStore;

  
  return (
    <div className="home flex h-screen mx-auto sm:px-4 pt-6 pb-8">
      <div className="m-auto">
        <div className="container mx-auto px-6 py-24 content-center text-center">
          <h3 className="text-6xl text-white font-bold ">
            Share Register
          </h3>
          {isLoggedIn && shareholder ? (
            <Fragment>
              <Header as='h2' className="text-white mb-12 text-3xl" inverted content={`Welcome back ${shareholder.first_name}`} />
              <Button
                size="huge"
                as={Link}
                to="/dashboard"
                primary
                content="Go to Dashboard"
              />
            </Fragment>
          ) : (
            <Fragment>
              <Header as='h2' className="text-white mb-12 text-3xl" inverted content={`Welcome to Muriwhenua Share Register`} />
              <Button
                primary
                size="huge"
                onClick={() => openModal(<LoginForm />)}
              >
                Login
              </Button>
              <Button
                primary
                size="huge"
                onClick={() => openModal(<ForgotPasswordForm />)}
              >
                Forgot Password
              </Button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(HomePage);
