import React from 'react'
import { observer } from 'mobx-react-lite';

export const Footer = () => {
    return (
      <div className="bg-white border-t">
        <div className="container mx-auto px-4">
          <div className="md:flex justify-between items-center text-sm">
            <div
              className="text-center md:text-left py-3 md:py-4 border-b md:border-b-0"
            >
              <a href="/" className="no-underline text-gray"
                >Legal &amp; Privacy</a
              >
            </div>
            <div className="md:flex md:flex-row-reverse items-center py-4">
              <div className="text-center mb-4 md:mb-0 md:flex">
                <div>
                  <a
                    href="/"
                    className="inline-block bg-blue-600 border border-blue-dark hover:bg-blue-600 hover:text-white px-3 py-2 text-white no-underline rounded"
                    >Need help?</a
                  >
                </div>
              </div>
              <div className="text-gray text-center md:mr-4">
                &copy; 2020 Muriwhenua
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default observer(Footer);