import React, { Fragment, useEffect, useContext } from "react";
import LoadingComponent from './LoadingComponent';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Route, Switch } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import NavBar from "../../features/navbar/NavBar";
import { Footer } from "../../features/footer/Footer";
import Account from "../../features/account/Account";
import Dashboard from "../../features/dashboard/Dashboard";
import NotFound from "./NotFound";
import ModalContainer from "../common/modal/ModalContainer";
import Settings from "../../features/settings/Settings";
import ResetPage from "../../features/user/ResetPage";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const {setAppLoaded, token, appLoaded} = rootStore.commonStore;
  const {getUser} = rootStore.shareholderStore;

  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded())
    } else {
      console.log('setAppLoaded');
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token])

  if (!appLoaded)  return <LoadingComponent content='Loading app...' />

  return (
    <Fragment>
      <ModalContainer />
      <ToastContainer position='bottom-right' />
      <Route exact path='/' component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
            <Switch>
              <Route path='/auth/reset' component={ResetPage} />
              <div className="bg-grey-lighter flex flex-col min-h-screen w-full pt-20">
                <NavBar />
                <Route exact path='/dashboard' component={Dashboard} />
                <Route exact path='/account' component={Account} />
                <Route exact path='/settings' component={Settings} />
                <Footer />
              </div>
              <Route component={NotFound} />
            </Switch>
        )}
      />
    </Fragment>
  );
};

export default observer(App);
