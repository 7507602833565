import { observable, action, runInAction, computed } from 'mobx';
import { ITransaction } from '../models/transaction';
import agent from '../api/agent';
import { RootStore } from './rootStore';

export default class TransactionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable transactionRegistry = new Map();
  @observable transaction: ITransaction | null = null;
  @observable loadingInitial = false;
  @observable loading = false;

  @computed get allTransactions() {
    return Array.from(this.transactionRegistry.values())
  }

  @action loadTransactions = async () => {
    this.loadingInitial = true;
    try {
      const transactions = await agent.Transactions.list(this.rootStore.shareholderStore.shareholder?.id!, true);
      runInAction('loading transactions', () => {
        transactions.data.forEach(transaction => {
          this.transactionRegistry.set(transaction.id, transaction);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction('load transactions error', () => {
        this.loadingInitial = false;
      });
    }
  };

  @action clearTransaction = () => {
    this.transaction = null;
  };

  getTransaction = (id: string) => {
    return this.transactionRegistry.get(id);
  };
}
