import React from 'react'
import { Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { IShareHolder } from '../../app/models/shareholder';

interface IProps {
  parents: IShareHolder[]
}

export const Parents: React.FC<IProps> = ({parents}) => {
  return (
    <div className="w-full">
        <h3 className="text-gray py-4 font-normal text-lg">Parents</h3>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Current Shares</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {parents.map(data => (
              <Table.Row key={data.id} >
                <Table.Cell>{data.first_name} {data.last_name}</Table.Cell>
                <Table.Cell>{data.shares}</Table.Cell>
              </Table.Row>
            ))}
            {parents.length === 0 && (
              <Table.Row >
                <Table.Cell>No Record found</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
    </div>
  )
}
export default observer(Parents);