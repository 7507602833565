import TransactionStore from './transactionStore';
import ShareholderStore from './shareholderStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import ModalStore from './modalStore';

configure({enforceActions: 'always'});

export class RootStore {
    transactionStore: TransactionStore;
    shareholderStore: ShareholderStore;
    commonStore: CommonStore;
    modalStore: ModalStore;

    constructor() {
        this.transactionStore = new TransactionStore(this);
        this.shareholderStore = new ShareholderStore(this);
        this.commonStore = new CommonStore(this);
        this.modalStore = new ModalStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());