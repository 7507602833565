import axios, { AxiosResponse } from 'axios';
import { history } from '../..';
import { ShareHolderResponseInterface, ILoginRequest, ILoginResponse, IShareHolder, IResetPasswordRequest, IResetPasswordResponse } from '../models/shareholder';
import { RequestInterface } from '../models/transaction';
import { toast } from 'react-toastify';

axios.defaults.baseURL = 'https://api.muriwhenua.iwi.nz';

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('jwt');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
}, error => {
    return Promise.reject(error);
})

axios.interceptors.response.use(
    response => {
      if (response.headers.authorization !== undefined) {
          const token = response.headers.authorization.replace('Bearer ', '')
          
          // how to call window.localStorage.setItem('jwt', token) ?
          window.localStorage.setItem('jwt', token);
      }
      return response
    },
    error => {
    if (error.message === 'Network Error' && !error.response) {
        toast.error('Network error - make sure API is running!')
    }
    const {status, data, config} = error.response;
    if (status === 404) {
        history.push('/notfound')
    }
    if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
        history.push('/notfound')
    }
    if (status === 500) {
        toast.error('Server error - check the terminal for more info!')
    }
    if (status === 401) {
        window.localStorage.removeItem('jwt')
        history.push('/')
    }
    throw error.response;
})

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios.post(url, formData, {
            headers: {'Content-type': 'multipart/form-data'}
        }).then(responseBody)
    }
};

const ShareHolder = {
    current: (): Promise<IShareHolder> => requests.get('/profile').then((data) => data.data),
    children: (id: number): Promise<ShareHolderResponseInterface> => requests.get(`/api/shareholders/${id}/children`),
    parents: (id: number): Promise<ShareHolderResponseInterface> => requests.get(`/api/shareholders/${id}/parents`),
    login: (user: ILoginRequest ): Promise<ILoginResponse> => requests.post(`/auth/token/shareholders/issue`, user),
    reset: (user: IResetPasswordRequest ): Promise<IResetPasswordResponse> => requests.post(`/shareholder/password/reset`, user),
    forgot: (email: string ): Promise<IResetPasswordResponse> => requests.post(`/shareholder/password/email`, email),
}

const Transactions = {
    list: (id: number, excludeHidden: boolean): Promise<RequestInterface> => requests.get(`/api/shareholders/${id}/transactions?excludeHidden=${excludeHidden}`),
}

export default {
    ShareHolder,
    Transactions
}