import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../app/stores/rootStore';

export const Cards = () => {
  const rootStore = useContext(RootStoreContext);
  const { shareholder, children,  getChildren } = rootStore.shareholderStore;

  useEffect(() => {
    getChildren();
  }, [getChildren]);

  return (
  <div className="bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow mb-6 sm:mt-3">
    <div className="flex items-center px-6 lg:hidden">
      <div className="flex-grow flex-no-shrink py-6">
        <div className="text-gray-700 mb-2 text-center">
          <span className="text-5xl">{shareholder?.shares}</span>
        </div>
        <div className="text-gray-700 text-sm text-center">
          Number of shares
        </div>
      </div>
    </div>
    <div className="hidden lg:flex">
      <div className="w-1/2 text-center py-8">
        <div className="border-r">
          <div className="text-gray-700 mb-2">
            <span className="text-5xl">{shareholder?.shares}</span>
          </div>
          <div className="text-sm uppercase text-gray tracking-wide">
          Number of shares
          </div>
        </div>
      </div>
      <div className="w-1/4 text-center py-8">
        <div className="border-r">
          <div className="text-gray-700 mb-2">
            <span className="text-5xl">--</span>
          </div>
          <div className="text-sm uppercase text-gray tracking-wide">
          Dividends
          </div>
        </div>
      </div>
      <div className="w-1/4 text-center py-8">
        <div>
          <div className="text-gray-700 mb-2">
          <span className="text-5xl">{children.length}</span>
          </div>
          <div className="text-sm uppercase text-gray tracking-wide">
            Children
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
export default observer(Cards)