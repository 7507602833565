import React, { useContext, useEffect } from 'react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';

export const RecentTransactions = () => {
  const rootStore = useContext(RootStoreContext);
  const {loadTransactions, allTransactions} = rootStore.transactionStore;

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);
  
  return (
    <div className="w-full">
        <h3 className="text-gray py-4 font-normal text-lg">Recent Activity</h3>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Details</Table.HeaderCell>
              <Table.HeaderCell>Deposit</Table.HeaderCell>
              <Table.HeaderCell>Withdraw</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {allTransactions.map(data => (
              <Table.Row key={data.id} >
                <Table.Cell>{data.trans_date}</Table.Cell>
                <Table.Cell>{data.details}</Table.Cell>
                <Table.Cell>{data.deposit}</Table.Cell>
                <Table.Cell>{data.withdraw}</Table.Cell>
                <Table.Cell>{data.total}</Table.Cell>
              </Table.Row>
            ))}
            {allTransactions.length === 0&& (
              <Table.Row>
                <Table.Cell>No record found.</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
    </div>
  )
}
export default observer(RecentTransactions);