import React from 'react'

const NotFound = () => {
  return (
    <div>
      <h1>Oops - we've looked everywhere but couldn't find this.</h1>
    </div>
  )
}

export default NotFound
