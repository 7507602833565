import React from "react";
import Cards from "./Cards";
import { observer } from "mobx-react-lite";
import RecentTransactions from "./RecentTransactions";

const Dashboard = () => {
  return (
    <div className="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
      <Cards />
      <RecentTransactions />  
    </div>
  );
};

export default observer(Dashboard);
